import { cn } from '@nextui-org/theme'
import React, { FC, ReactNode } from 'react'

interface Props {
  width?: "container" | "full" | "80" | "60";
  children: ReactNode;
  className?: string;
}

const Container: FC<Props> = ({ width="container", children, className }) => {

  return (
    <div className={cn((width === "container") ? "container" : `w-${width}`, className)}>
      {children}
    </div>
  )
}

export default Container