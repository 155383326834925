import ReactGA from "react-ga4";

export function setEvent(
  category: string,
  action: string,
  label?: string,
): void {
  ReactGA.event({
    category,
    action,
    label,
  });
}

// export function pageView(path: Pages): void {
//   ReactGA.pageview(`/${path}`);
// }

export const initGA = () => {
  console.log("GA working");
  // ReactGA.initialize('UA-43083343-1');
  ReactGA.initialize("G-08WXX3V5GS", { testMode: true });
  // ReactGA.initialize('G-08WXX3V5GS');
};
