export type PasswordState = {
  password: string | undefined;
};

export type PasswordAction =
  | { type: 'SET_PASSWORD'; payload: string }
  | { type: 'CLEAR_PASSWORD' };

export type TypeProjectJSON = {
  title: string;
  summary: string;
  type: string;
  client: string;
  role: string;
  link: string;
  icon: string;
  photo: string;
  OGImage: string;
  year: string;
  publishDate: string;
  duration: number;
};

export enum FormStates {
  Default = "Default",
  Pending = "Pending",
  Success = "Success",
  Error = "Error",
}

export type TypeFigmaPluginJSON = {
  title: string;
  type: "Public" | "Private";
  year: number;
  video: string;
  icon: string;
  description: string;
};

export enum Pages {
  Index = "home",
  About = "about",
  Figma = "figma",
  Bookshelf = "bookshelf",
  Contact = "contact",
  Links = "links",
  DarkVader = "dark-vader",
  ScalableTypography = "building-a-scalable-typography-system",
  ReadingExperience = "designing-reading-experience",
  SoundCloudMobileUpload = "building-mobile-upload-for-creators",
  SoundCloudPlayerAccessibility = "improving-soundcloud-player-accessibility",
  DesignSystemsAtSoundCloud = "design-systems-at-soundcloud",
  Error404 = "404",
  Error500 = "500",
}

export enum EventsEnum {
  PasswordCategory = "password category",
  PasswordAttempt = "password attempt",
  PasswordSuccess = "password success",
  BookCall = "book a call"
}
