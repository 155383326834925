"use client";

import PasswordContext from "@/utils/password-context";
import passwordReducer from "@/utils/password-reducer";
import { FC, ReactNode, useReducer } from "react";

// Add the Provider component
const PasswordProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(passwordReducer, { password: undefined });
    
    return (
      <PasswordContext.Provider value={{ state, dispatch }}>
        {children}
      </PasswordContext.Provider>
    );
  };

export default PasswordProvider;