import { PasswordAction, PasswordState } from "./types";

// Add the reducer function
const passwordReducer = (state: PasswordState, action: PasswordAction): PasswordState => {
    switch (action.type) {
      case 'SET_PASSWORD':
        return { ...state, password: action.payload };
      case 'CLEAR_PASSWORD':
        return { ...state, password: undefined };
      default:
        return state;
    }
  };

  export default passwordReducer;